<template>
  <div class="alert-validation-message">
    <div class="icon-container">
      <div class="icon-container__content">
        <i class="ion ion-close-outline"></i>
      </div>
    </div>
    <div class="information-container">
      <h5 class="information-container__title">Please review the cargo details</h5>
      <ul class="information-container-list">
        <li
          class="information-container-list__item"
          v-for="(item, index) in lineItemsMessageError"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <p class="information-container__text">
        Kindly verify that the entered information is accurate, or reach out to our Operations Team
        via email <span v-if="!isBranded">{{ email }}</span> for a more detailed quote.
      </p>
    </div>
  </div>
</template>

<script>
import { getTokenUserInfo } from '../router/routerHelpers';

export default {
  name: "AlerValidationMessage",
  props: {
    lineItemsMessageError: Array,
    quoteMode: String,
  },
  computed: {
    email() {
      switch (this.quoteMode) {
        case "all-options":
          return "at opendeck@goglt.com";
        case "ltl":
          return "at ltl@goglt.com";
        case "expedited":
          return "at ftl@goglt.com";
        case "flatbed":
          return "at opendeck@goglt.com";
        default:
        case "ftl":
          return "at ftl@goglt.com";
        case "reefer":
          return "at tempcontrol@goglt.com";
      }
    },
  },
  data() {
    /* eslint-disable max-len */
    return {
      isBranded: false,
    };
  },
  created() {
    const accountInfo = getTokenUserInfo();
    this.isBranded = accountInfo.is_branded;
  }
};
</script>

<style lang="scss" scoped>
.alert-validation-message {
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background: #ffd4d4;
  color: #e9554c;
}
.icon-container {
  border: 1px solid red;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #e9554c !important;
    i {
      font-size: 22px;
      color: white;
    }
  }
}
.information-container {
  margin-left: 10px;
  text-align: start;
  &__title {
    font-size: 1.2rem;
    color: #e9554c;
  }
  &__text {
    margin: 0px;
    font-size: 1rem;
  }
}
</style>
